import React from 'react';
import Home from '../src/pages/Home'; // Import komponentu Home

function App() {
  return (
    <div className="App">
      <Home />  {/* Wyświetla sekcję Home */}
    </div>
  );
}

export default App;
